.blankVideo {
  margin-top: 20px;
  width: 100%;
  height: 200px;
  background-color: #000;
  text-align: center;
}

.blankVideo span {
  color: #fff;
  position: relative;
  top: 50%;
}

.videoplayer {
  margin-top: 20px;
}